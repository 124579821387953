@import "../../styles/vars.styl"
/* This is to make touchable space bigger */
.rc-slider-handle:before
    cursor: grab;
    content: '';
    position: absolute;
    touch-action: pan-x;
    border: none;
    background-color: rgb(255, 255, 255);
    margin-left: -12px;
    margin-top: -10px;
    width: 13px;
    height: 11px;
    left: 19.0476%;
    padding: 13px;
    opacity: 0;

.rc-slider-mark-text
    white-space: nowrap;

.rc-slider-dot
    bottom: -1px
