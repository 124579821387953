@import "../../styles/vars.styl"
.content
	position: relative
	// height: 100vh;
	// overflow-y: scroll;
	margin-left: 40px;
	-webkit-transform: translate3d(0,0,0);

	@media screen and (min-width: 769px)
		-webkit-transform: none;
		margin-left: 0;

	&:before
		top: 0

	&:after
		bottom: 0

	&__wrapper
		height 100%
		position: relative

	&__listing
		position: relative
		-webkit-backface-visibility: hidden

		@media screen and (max-width: 768px)
			-webkit-transform: translate3d(0,0,0);
			z-index: initial

		&_mobile
			top: initial

		&-head
			padding-bottom: 8px
			position: relative

			@media screen and (max-width: 768px)
				padding: 8px 0

		&-side
			width: 240px
			height: 190px
			padding-top: 43px
			margin-right: 0px;
			display: block
			position: relative
			z-index: 5
			background-image: linear-gradient(to bottom, #333842, #171a1f);
			opacity: 0.2;
			margin-left: 16px;
			position: relative;
			display: none;
			width: 180px;
			border-radius: 4px;

	&__flights-side
		width: 240px
		height: 190px
		padding-top: 43px
		margin-right: 0px;
		display: block
		position: relative
		z-index: 5
		background-image: linear-gradient(to bottom, #333842, #171a1f);
		opacity: 0.2;
		margin-left: 16px;
		position: relative;
		display: none;
		width: 180px;
		border-radius: 4px;

		@media screen and (min-width: 1280px)
			display: block;
			width: 180px;

		@media screen and (min-width: 1680px)
			width: 300px;

	&__lock
		position: relative
		top: -2px
