@import "../../styles/vars.styl"
.scrollbar
	position: absolute
	right: 5px
	width: 5px
	top: 10px
	bottom: @top
	background: rgba(#fff, .1)
	border-radius: 3px
	cursor: pointer
	z-index: 16

	&__thumb
		background: #fff
		opacity: .3
		border-radius: 3px
		transition: opacity .15s $animations.swifter, height .15s $animations.swifter
		user-select: none
		height: '100%'
		top: 0
		right: 0

		&:hover
		&_active
			opacity: .8

	&_dark
		background: rgba(#000, .1)

		.scrollbar__track
			opacity: .5

			&:hover
			&_active
				opacity: 1

	&_hidden
		visibility: hidden