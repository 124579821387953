@import "../../styles/vars.styl"
@import "../../styles/helpers.styl"

.suggest
	position: absolute
	top: calc(100% + 4px)
	left: 0
	right: 0
	border-radius: 3px
	background: $colors.blue
	z-index: 100
	overflow: hidden
	visibility: hidden
	opacity: 0
	transform: translateY(12px)
	transition: transform .3s ease-in-out, visibility .2s ease-in-out, opacity .2s ease-in-out

	@media screen and (max-width: 767px)
		background: #202531

	&_active,
	&_error
		transform: translateY(-3px)
		opacity: 1
		visibility: visible

		@media screen and (max-width: 767px)
			position: fixed;
			top: 116px
			left: 0
			right: 0
			height: calc(100vh - 116px)
			border-radius: 0
			box-shadow: none

	&__item
		padding: 10px 20px
		font-set: '14-light'
		color: getContrastColor($colors.blue)
		transition: background .2s ease-in-out
		cursor: pointer
		user-select: none
		display: flex
		justify-content: space-between

		&:hover
		&_active
			background: #373B45;

		@media screen and (min-width: 769px)
			&:last-child
				margin-bottom 0

			&:hover
			&_active
				background: ($colors.blue + 12%)


	&__label
		margin-right: 10px
		opacity: .5
