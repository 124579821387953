@import "../../styles/vars.styl"
@import "../../styles/helpers.styl"

.sidebar
	width: 120px
	height: 100%
	overflow: hidden
	user-select none
	background rgba(255,255,255,0.2)
	backdrop-filter blur(15px)
	padding-top 17px

	&:after
		content: ''
		display: block
		height: 100%
		background: #D4D5D9

	&__add
		margin 25px auto 0
		color: rgba(#000, .5)
		background-color: #fff
		transition: background .15s ease-in-out, color .15s ease-in-out
		width: 37px;
		height: 37px;
		border-radius: 50%;
		display:flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		font-set: '24-medium';
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
		display flex
		align-items center
		justify-content center

		&:hover
			color: #000

	&__add-symbol
		position: relative
		line-height 1

	&__progress
		position: absolute;
		height: 4px;
		width: 100%;
		left: 0;
		bottom: 0;
		z-index: 0; // in case we have custom scrollbar in sidebar

	&__delimiter
		content: '';
		border-bottom: 1px solid #433B4A;
		height: 1px;
		width: calc(100% - 25px);
		position: absolute;

		left: 50%;
		transform: translateX(-50%);

		&_pre

			top: -2px;
		&_post
			bottom: 0;


	&__flag
		display: inline-block;
		vertical-align: middle;
		height: 12px;
		width: 18px;
		margin-right: 4px;
		position: relative;
		top: -1px;

	&__item
		padding 13px 9px
		color: #fff
		cursor: pointer
		transition: background .15s ease-in-out
		font-size: 14px;
		font-weight: 400;
		line-height: 18px;
		position: relative

		&_active
		&:hover
			background-color rgba(255, 255, 255, 0.2);

			.sidebar
				&__title
				&__param
					opacity: 1

		&:hover
			.sidebar__icon_close
				display: block

		&_tab:after
			content: ''
			display: none
			height: 8px
			width: 8px
			border-radius: 5px;
			background-color: #786975;

		&_active
			.sidebar__delimiter
				border-bottom: 1px solid #1d2132;
				z-index: 34;

			&:after
				background-color: white

	&__logo
		text-align: center
		cursor: default
		display: block;
		margin-bottom: -5px;
		padding: 18px 11px

		&:hover
			background: #D4D5D9

	&__icon
		width: 20px
		height: @width
		color: #797b82
		transition: color .15s ease-in-out
		position: absolute
		right: 9px
		top: 5px
		background-color: #d5d6da
		border-radius: 50%

		&:hover
			color: rgb(29,34, 50)

		&_close
			display: none

	&__closer-inner
		width: 100%
		height: 100%
		display: flex
		align-items: center
		justify-content: center
		font-size: 10px

	&__title
		font-weight 600
		font-size 15px
		line-height 20px

	&__destination
		display: inline-block
		vertical-align: middle

	&__param
		display: block
		font-size 12px
		line-height 16px
