@import "../../styles/vars.styl"
@import "../../styles/helpers.styl"

.switcher
	color: #fff
	display: flex
	align-items: center

	&__item
		user-select: none
		cursor: pointer
		font-size: 14px
		display: inline-flex

		&_travelers
			font-size: 21px;
			padding-bottom: 4px;
			margin-right: 20px;
			display: flex;

		&_travel-type
			margin-right: 11px;

			@media screen and (max-width: 768px)
				margin-right: 14px;

		&_more-travelers
			position: relative;

	&__content
		opacity: .3
		transition: opacity .15s ease-in-out
		cursor: pointer
		display: inline-flex
		align-items: flex-end
		font-size: inherit

		@media (hover: hover)
			&:hover
				opacity: 1

		@media screen and (max-width: 768px)
			font-size: 16px
			font-weight: 200

	&__field
		opacity: 0
		position: absolute
		left: -10000px

		&:checked + .switcher__content
			opacity: 1

	&_size
		&_large
			font-set: '20-medium'
